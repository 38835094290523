import { cn } from '@/core/ui/utils';
import { FCC } from '@/types/common';

type SkeletonProps = {
  className?: string;
  width?: number;
  height?: number;
};

export const Skeleton: FCC<SkeletonProps> = ({
  children,
  className,
  height,
  width,
}) => (
  <div
    style={{ height, width }}
    className={cn(
      'rounded-xl',
      'overflow-hidden',
      'relative',
      'h-auto',
      'w-auto',
      'before:absolute',
      'before:bottom-0',
      'before:top-0',
      'before:left-0',
      'before:right-0',
      'before:bg-surface-neutral-default',
      'after:absolute',
      'after:bottom-0',
      'after:top-0',
      'after:left-0',
      'after:right-0',
      'after:bg-gray-200',
      'after:animate-shine',
      'after:bg-[length:200%_100%]',
      'after:z-10',
      'after:bg-skeleton',
      className
    )}
  >
    {children}
  </div>
);
