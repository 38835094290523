import { memo } from 'react';

import ProductImageFallback from '@/core/images/product-image-placeholder.svg';
import { getImageKitUrl } from '@/core/transformers/getImageKitUrl';
import { cn } from '@/core/ui/utils';
import { FC } from '@/types/common';
import { withImageSize } from '@lib/api/with-image-size';
import { Img } from '@lib/img/components/Img';

import { Skeleton } from '../Skeleton/Skeleton';

const PRODUCT_TILE_SIZE = 96;

export const ProductImage: FC<ProductImageProps> = memo(({ alt, url }) => {
  const originSrc = withImageSize(url ?? '', 'medium');

  return (
    <Img
      fallback={
        <ProductImageFallback aria-hidden className={cn('text-primary-200')} />
      }
      placeholder={
        <Skeleton width={PRODUCT_TILE_SIZE} height={PRODUCT_TILE_SIZE} />
      }
      width={PRODUCT_TILE_SIZE}
      height={PRODUCT_TILE_SIZE}
      alt={alt}
      src={getImageKitUrl({
        url: originSrc,
        params: {
          w: PRODUCT_TILE_SIZE,
          h: PRODUCT_TILE_SIZE,
          cm: 'pad_resize',
        },
      })}
      srcSetObj={{
        '2.5x': getImageKitUrl({
          url: originSrc,
          params: {
            w: PRODUCT_TILE_SIZE,
            h: PRODUCT_TILE_SIZE,
            dpr: 2.5,
            cm: 'pad_resize',
          },
        }),
        '4.5x': getImageKitUrl({
          url: originSrc,
          params: {
            w: PRODUCT_TILE_SIZE,
            h: PRODUCT_TILE_SIZE,
            dpr: 4.5,
            cm: 'pad_resize',
          },
        }),
      }}
      className={cn('object-scale-down', 'w-full')}
    />
  );
});

type ProductImageProps = {
  url?: string;
  url2x?: string;
  alt: string;
  onLoad?: () => void;
};
